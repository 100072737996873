.question {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 45px;
}

.question > h1 {
  margin: 0 0 15px;
  text-align: start;
  font-size: 30px;
  line-height: 160%;
  color: #424242;
}

.invalidName {
  border-bottom: 1px solid #c60000;
}

.rangeContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 25px;
}

.rangeContainer > h2 {
  font-weight: 400;
  font-size: 25px;
  line-height: 40px;
  margin: 0 0 15px;
}

.rangeContainer > input {
  width: 365px;
  -webkit-appearance: none;
  background: transparent;
  margin-bottom: 20px;
}

/* Thumb design */
.rangeContainer > input::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 25px;
  width: 25px;
  border-radius: 5px;
  background: #8aaf45;
  cursor: pointer;
  margin-top: -10.5px;
  transition: transform 0.15s ease-out;
}

.rangeContainer > input::-moz-range-thumb {
  border: none;
  height: 25px;
  width: 25px;
  border-radius: 5px;
  background-color: #8aaf45;
  cursor: pointer;
  transition: transform 0.1s ease-out;
}

.rangeContainer > input::-ms-thumb {
  border: none;
  height: 25px;
  width: 25px;
  border-radius: 5px;
  background-color: #8aaf45;
  cursor: pointer;
  transition: background-color 0.15s ease-out;
}

.rangeContainer > input:active::-webkit-slider-thumb {
  background-color: #68901c;
}

.rangeContainer > input:active::-moz-range-thumb {
  background-color: #68901c;
}

.rangeContainer > input:focus {
  outline: none;
}

/* Track design */

.rangeContainer > input::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  /* background: #ede9de; */
  /* background: linear-gradient(to right, #8AAF45 0%, #8AAF45 20%, #EDE9DE 20%, #EDE9DE 100%); */
  border-radius: 2px;
  border: none;
}

.rangeContainer > input::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  /* background: #ede9de; */
  /* background: linear-gradient(to right, #8AAF45 0%, #8AAF45 20%, #EDE9DE 20%, #EDE9DE 100%); */
  border-radius: 2px;
  border: none;
}

.rangeContainer > .labelContainer {
  width: 365px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.rangeContainer > .labelContainer > .label {
  font-weight: 400;
  font-size: 25px;
  line-height: 40px;
  width: 25px;
  min-width: 25px;
  text-align: center;
  box-sizing: border-box;
  padding-left: 3px;
  transition: color 0.1s ease-out;
}

.active {
  color: #8aaf45;
}

@media (max-width: 1190px) {
  .question {
    margin-bottom: 26px;
    width: 100%;
  }

  .question > h1 {
    margin: 0 0 20px;
    font-size: 18px;
    line-height: 29px;
  }

  .rangeContainer {
    margin-bottom: 20px;
    width: 100%;
  }

  .rangeContainer > h2 {
    font-size: 16px;
    line-height: 26px;
    margin: 0 0 10px;
  }

  .rangeContainer > input {
    width: 100%;
    -webkit-appearance: none;
    background: transparent;
    margin-bottom: 20px;
  }

  /* Thumb design */
  .rangeContainer > input::-webkit-slider-thumb {
    height: 20px;
    width: 20px;
    margin-top: -9px;
  }

  .rangeContainer > input::-moz-range-thumb {
    height: 20px;
    width: 20px;
  }

  .rangeContainer > input::-ms-thumb {
    height: 20px;
    width: 20px;
  }

  /* Track design */

  .rangeContainer > input::-webkit-slider-runnable-track {
    height: 2px;
    border-radius: 1px;
  }

  .rangeContainer > input::-moz-range-track {
    height: 2px;
    border-radius: 1px;
  }

  .rangeContainer > .labelContainer {
    width: 100%;
  }

  .rangeContainer > .labelContainer > .label {
    font-size: 16px;
    line-height: 26px;
    width: 20px;
    min-width: 20px;
    padding-left: 3px;
    margin-bottom: 5px;
  }
}
