.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 60px;
}

.linkList {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
}

.linkList > a {
  margin-right: 30px;
}

.linkList > a > img {
  width: 91px;
  height: 91px;
}

.email {
  font-weight: 600;
  font-size: 35px;
  line-height: 43px;
  color: #424242;
  text-decoration: none;
  margin-bottom: 40px;
}

.details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  line-height: 24px;
  width: 100%;
}

.designer {
  color: #424242;
  text-decoration: none;
}

.designer > span {
  color: #8aaf45;
  font-weight: 600;
}

.info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.rights {
  font-weight: 400;
  color: #808080;
}

.links {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.link {
  text-decoration: none;
  font-weight: 400;
  margin-left: 30px;
  color: #8aaf45;
}

@media (max-width: 1640px) {
  .footer {
    margin-bottom: 40px;
  }

  .linkList {
    margin-bottom: 30px;
  }

  .linkList > a {
    margin-right: 20px;
  }

  .linkList > a > img {
    width: 65px;
    height: 65px;
  }

  .email {
    font-size: 25px;
    line-height: 31px;
    margin-bottom: 30px;
  }

  .details {
    font-size: 15px;
    line-height: 18px;
  }

  .link {
    margin-left: 20px;
  }
}

@media (max-width: 1190px) {
  .footer {
    margin-bottom: 30px;
  }

  .linkList {
    margin-bottom: 20px;
  }

  .linkList > a {
    margin-right: 19px;
  }

  .linkList > a > img {
    width: 56px;
    height: 56px;
  }

  .email {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 30px;
  }

  .details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 14px;
    line-height: 17px;
  }

  .designer {
    color: #424242;
    text-decoration: none;
  }

  .designer > span {
    color: #8aaf45;
    font-weight: 600;
  }

  .info {
    display: flex;
    flex-direction: inherit;
    align-items: inherit;
  }

  .rights {
    margin-bottom: 10px;
  }

  .link {
    margin-right: 20px;
    margin-left: 0;
  }

  .designer {
    display: none;
  }
}
