.question {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 45px;
}

.question > h1 {
  margin: 0 0 15px;
  text-align: start;
  font-size: 30px;
  line-height: 160%;
  color: #424242;
}

.invalidName {
  border-bottom: 1px solid #c60000;
}

.selector {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  cursor: pointer;
  margin-bottom: 15px;
}

.point {
  width: 25px;
  min-width: 25px;
  margin-top: 11px;
  height: 25px;
  background-color: #ede9de;
  border-radius: 5px;
  margin-right: 15px;
  transition: background-color 0.2s ease-out;
}

.selected {
  background-color: #8aaf45;
}

.selector > p {
  margin: 0;
  font-weight: 400;
  font-size: 25px;
  line-height: 48px;
  text-align: start;
}

.otherBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.otherBlock > .selector {
  margin-bottom: 0px;
}

.otherBlock > input {
  width: 710px;
  padding: 5px 40px 10px;
  font-weight: 400 !important;
  color: #808080;
  font-size: 25px;
  line-height: 30px;
  border: none;
  border-bottom: 1px solid #808080;
  outline: none;
  transition: border 0.15s ease-out;
  transition: color 0.15s ease-out;
  font-family: 'Montserrat';
  margin-bottom: 5px;
  box-sizing: border-box;
}

.active {
  border-bottom: 1px solid #8aaf45 !important;
  color: #424242 !important;
}

.invalid {
  border-bottom: 1px solid #c60000;
}

.errorText {
  margin-left: 40px;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #c60000;
  transition: all 0.15s ease-out;
  opacity: 0;
}

.hidden {
  opacity: 1;
}

@media (max-width: 1190px) {
  .question {
    margin-bottom: 26px;
  }

  .question > h1 {
    margin: 0 0 15px;
    font-size: 18px;
  }

  .point {
    width: 20px;
    min-width: 20px;
    margin-top: 3px;
    height: 20px;
    margin-right: 10px;
  }

  .selector > p {
    font-size: 16px;
    line-height: 26px;
  }

  .otherBlock {
    width: 100%;
  }

  .otherBlock > .selector {
    margin-bottom: 0px;
  }

  .otherBlock > input {
    width: 100%;
    padding: 5px 2px 2px;
    font-size: 16px;
    line-height: 26px;
  }

  .errorText {
    font-size: 12px;
    line-height: 19px;
  }
}
