.question {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 45px;
}

.question > h1 {
  margin: 0 0 15px;
  text-align: start;
  font-size: 30px;
  line-height: 160%;
  color: #424242;
}

.invalidName {
  border-bottom: 1px solid #c60000;
}

.question > input {
  width: 150px;
  padding: 5px 40px 10px;
  font-weight: 400 !important;
  color: #424242;
  font-size: 25px;
  line-height: 30px;
  border: none;
  border-bottom: 2px solid #808080;
  outline: none;
  transition: border 0.15s ease-out;
  font-family: 'Montserrat';
  /* margin-bottom: 5px; */
  text-align: center;
  /* caret-color: transparent; */
}

.question > input:focus {
  border-bottom: 2px solid #8aaf45 !important;
  /* animation-name: blink;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 0.8s; */
}

@keyframes blink {
  0% {
    color: rgb(170, 170, 170);
  }
  50% {
    color: black;
  }
  100% {
    color: rgb(170, 170, 170);
  }
}

@media (max-width: 1190px) {
  .question {
    margin-bottom: 45px;
  }

  .question > h1 {
    margin: 0 0 10px;
    font-size: 18px;
  }

  .question > input {
    width: 100%;
    padding: 5px 2px 2px;
    font-size: 16px;
    line-height: 26px;
    border-bottom: 1px solid #808080;
    text-align: start;
  }

  .question > input:focus {
    border-bottom: 1px solid #8aaf45 !important;
  }
}
