.headerContainer {
  position: absolute;
  width: 100%;
  top: 50px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  width: 100%;
  display: grid;
  grid-template-columns: 80px calc(100% - 160px) 80px;
  align-items: center;
}

.logoLink {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
}

.logoLink > .logo {
  width: 60px;
  height: 60px;
}

.linkList {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.linkList > .link {
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  text-decoration: none;
  color: #424242;
  margin-right: 75px;
  cursor: pointer;
}

.linkList > .link:last-child {
  margin-right: 0;
}

.login {
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  text-decoration: none;
  color: #424242;
  cursor: pointer;
}

@media (max-width: 1640px) {
  .headerContainer {
    top: 40px;
  }

  .logoLink > .logo {
    width: 50px;
    height: 50px;
  }

  .linkList > .link {
    font-size: 22px;
    line-height: 26px;
    margin-right: 60px;
  }
  
  .linkList > .link:last-child {
    margin-right: 0;
  }
  
  .login {
    font-size: 22px;
    line-height: 36px;
  }
}
