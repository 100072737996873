.blur {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(138, 175, 69, 0.3);
  backdrop-filter: blur(100px);
  animation-name: appear;
  animation-duration: 0.4s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  z-index: 100;
}

.closing {
  animation-name: disappear;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes disappear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.container {
  position: relative;
  display: flex;
  max-height: 90%;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  border-radius: 30px;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: 0;
  background-color: white;
}

.container::-webkit-scrollbar {
  display: none;
}

.container > img {
  width: auto;
  height: 100%;
  object-fit: cover;
}

.content {
  width: 700px;
  box-sizing: border-box;
  padding: 35px 50px;
  text-align: start;
}

.content > h1 {
  font-weight: 600;
  font-size: 30px;
  line-height: 48px;
  margin: 0 0 5px;
}

.content > p {
  font-size: 25px;
  line-height: 40px;
  margin: 0 0 37px;
}

.inputBlock {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}

.inputBlock > input {
  width: 100%;
  box-sizing: border-box;
  padding: 21px 30px 25px;
  font-size: 25px;
  line-height: 30px;
  background-color: #ede9de;
  border-radius: 20px;
  outline: none;
  border: none;
  color: #424242;
  font-family: 'Montserrat';
  border: 2px solid transparent;
  transition: all 0.25s ease-out;
}

.inputBlock > .inputInvalid {
  border-color: rgba(198, 0, 0, 1);
  color: rgba(198, 0, 0, 1);
}

.inputBlock > .error {
  position: absolute;
  top: 10px;
  right: 30px;
  color: rgb(141, 19, 19);
  font-weight: 400;
  font-size: 40px;
  line-height: 40px;
  margin-top: 7px;
}

.warn {
  margin-top: -10px;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  color: #c60000;
  margin-bottom: 15px;
  transition: all 0.2s ease-out;
  opacity: 1;
}

.warnHidden {
  opacity: 0;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 60px !important;
  height: 60px !important;
}

.btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 21px 0 26px;
  background-color: #e8e8e8;
  border-radius: 20px;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  transition: all 0.2s ease-out;
}

.valid {
  background-color: #8aaf45;
  color: white;
  cursor: pointer;
}

.valid:active {
  transform: scale(0.95);
}

@media (max-width: 1640px) {
  .container {
    border-radius: 25px;
  }

  .container > img {
    height: 460px;
  }

  .content {
    width: 500px;
    padding: 25px 35px;
  }

  .content > h1 {
    font-size: 26px;
    line-height: 36px;
  }

  .content > p {
    font-size: 22px;
    line-height: 32px;
    margin: 0 0 37px;
  }

  .inputBlock {
    margin-bottom: 15px;
  }

  .inputBlock > input {
    padding: 16px 24px 20px;
    font-size: 22px;
    line-height: 28px;
    border-radius: 15px;
  }

  .inputBlock > .error {
    position: absolute;
    top: 5px;
    right: 25px;
    color: rgb(141, 19, 19);
    font-weight: 400;
    font-size: 40px;
    line-height: 40px;
    margin-top: 7px;
  }

  .warn {
    margin-top: -7px;
    font-size: 14px;
    color: #c60000;
    margin-bottom: 15px;
  }

  .close {
    width: 50px !important;
    height: 50px !important;
  }

  .btn {
    padding: 16px 24px 20px;
    border-radius: 15px;
    box-sizing: border-box;
    font-size: 22px;
    line-height: 28px;
    width: 100%;
  }
}

@media (max-width: 1190px) {
  .container {
    border-radius: 20px;
    width: calc(100% - 40px);
    max-width: 450px;
  }

  .container > img {
    display: none;
  }

  .content {
    width: 100%;
    padding: 20px 20px;
  }

  .content > h1 {
    font-size: 18px;
    line-height: 25px;
  }

  .content > p {
    font-size: 16px;
    line-height: 26px;
    margin: 0 0 25px;
  }

  .inputBlock {
    margin-bottom: 10px;
  }

  .inputBlock > input {
    padding: 13px 30px 16px 20px;
    font-size: 16px;
    line-height: 20px;
    border-radius: 10px;
  }

  .inputBlock > .error {
    position: absolute;
    top: 9px;
    right: 15px;
    color: rgb(141, 19, 19);
    font-weight: 400;
    font-size: 30px;
    line-height: 30px;
    margin-top: 0;
  }

  .warn {
    margin-top: -5px;
    font-size: 12px;
    color: #c60000;
    margin-bottom: 10px;
  }

  .close {
    display: block !important;
    width: 40px !important;
    height: 40px !important;
  }

  .btn {
    padding: 13px 0 16px;
    border-radius: 10px;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 20px;
    width: 100%;
  }
}
