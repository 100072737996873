.blur {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(138, 175, 69, 0.3);
  backdrop-filter: blur(100px);
  animation-name: appear;
  animation-duration: 0.4s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  z-index: 100;
}

.closing {
  animation-name: disappear;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes disappear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.container {
  position: relative;
  display: flex;
  max-height: 90%;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  border-radius: 30px;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: 0;
  background-color: white;
}

.container::-webkit-scrollbar {
  display: none;
}

.container > img {
  width: auto;
  height: 100%;
  object-fit: cover;
}

.content {
  width: 700px;
  box-sizing: border-box;
  padding: 35px 50px;
  text-align: start;
}

.content > div > h1 {
  font-weight: 600;
  font-size: 30px;
  line-height: 48px;
  margin: 0 0 5px;
}

.content > div > p {
  font-size: 25px;
  line-height: 40px;
  margin: 0 0 183px;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 60px !important;
  height: 60px !important;
}

.btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 21px 0 26px;
  border-radius: 20px;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  transition: all 0.2s ease-out;
  background-color: #8aaf45;
  color: white;
  cursor: pointer;
  text-decoration: none;
}

.btn:active {
  transform: scale(0.95);
}

@media (max-width: 1640px) {
  .container {
    border-radius: 25px;
  }

  .container > img {
    height: 420px;
  }

  .content {
    width: 500px;
    padding: 25px 35px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .content > div > h1 {
    font-size: 26px;
    line-height: 36px;
  }

  .content > div > p {
    font-size: 22px;
    line-height: 32px;
    margin: 0 0 37px;
  }

  .close {
    width: 50px !important;
    height: 50px !important;
  }

  .btn {
    padding: 16px 24px 20px;
    border-radius: 15px;
    box-sizing: border-box;
    font-size: 22px;
    line-height: 28px;
    width: 100%;
  }
}

@media (max-width: 1190px) {
  .container {
    border-radius: 20px;
    width: calc(100% - 40px);
    max-width: 450px;
  }

  .container > img {
    display: none;
  }

  .content {
    width: 100%;
    padding: 20px 20px;
  }

  .content > div > h1 {
    font-size: 18px;
    line-height: 25px;
  }

  .content > div > p {
    font-size: 16px;
    line-height: 26px;
    margin: 0 0 25px;
  }

  .close {
    display: block !important;
    width: 40px !important;
    height: 40px !important;
  }

  .btn {
    padding: 13px 0 16px;
    border-radius: 10px;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 20px;
    width: 100%;
  }
}
