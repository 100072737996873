.headSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-top: 220px;
}

.headSection > .content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 635px;
}

.headSection > img {
  width: 900px;
  height: auto;
}

.headSection > .content > h1 {
  font-weight: 800;
  font-size: 80px;
  line-height: 98px;
  margin: 0 0 40px;
  text-align: start;
}

.headSection > .content > p {
  font-weight: 400;
  font-size: 30px;
  line-height: 48px;
  margin: 0 0 65px;
  text-align: start;
}

.signup {
  width: fit-content;
  padding: 34px 50px 40px;
  background-color: #8aaf45;
  border-radius: 30px;
  color: white;
  font-weight: 700;
  font-size: 35px;
  line-height: 43px;
  cursor: pointer;
}

.stats {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin-top: 140px;
}

.stats > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 100px;
  text-align: center;
}

.stats > div:last-child {
  margin-right: 0;
}

.stats > div > h3 {
  margin: 0 0 15px;
  font-weight: 700;
  font-size: 60px;
  line-height: 73px;
  color: #8aaf45;
}

.stats > div > p {
  margin: 0 0 0;
  font-weight: 400;
  font-size: 30px;
  line-height: 37px;
}

.featureBlock,
.featureBlockEven {
  max-width: 1760px;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin-top: 100px;
  box-sizing: border-box;
  align-self: flex-end;
  padding: 0 0 0 40px;
}

.featureBlockEven {
  flex-direction: row;
  align-self: flex-start;
  padding: 0 40px 0 0;
}

.featureBlock > .content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.featureBlockEven > .content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
}

.featureBlock > .content > h2,
.featureBlockEven > .content > h2 {
  font-weight: 600;
  font-size: 50px;
  line-height: 61px;
  margin-bottom: 20px;
  margin-top: 0;
}

.featureBlock > .content > p,
.featureBlockEven > .content > p {
  font-weight: 400;
  font-size: 35px;
  line-height: 56px;
  margin: 0px;
}

.featureBlock > img,
.featureBlockEven > img {
  width: 775px;
  height: auto;
}

.startBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 140px auto 145px 0;
}

.startBlock > h1 {
  margin: 0 0 0 50px;
  font-weight: 400;
  font-size: 35px;
  line-height: 56px;
  text-align: left;
}

@media (max-width: 1640px) {
  .headSection {
    margin-top: 200px;
  }

  .headSection > .content {
    width: 455px;
  }

  .headSection > img {
    width: 620px;
  }

  .headSection > .content > h1 {
    font-size: 58px;
    line-height: 70px;
    margin: 0 0 30px;
    text-align: start;
  }

  .headSection > .content > p {
    font-size: 22px;
    line-height: 35px;
    margin: 0 0 45px;
  }

  .signup {
    padding: 25px 36px 28px;
    border-radius: 20px;
    font-size: 25px;
    line-height: 31px;
  }

  .stats {
    margin-top: 100px;
  }

  .stats > div {
    margin-right: 75px;
  }

  .stats > div:last-child {
    margin-right: 0;
  }

  .stats > div > h3 {
    margin: 0 0 10px;
    font-size: 43px;
    line-height: 53px;
  }

  .stats > div > p {
    margin: 0 0 0;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
  }

  .featureBlock,
  .featureBlockEven {
    max-width: 1350px;
    margin-top: 70px;
  }

  .featureBlock > .content > h2,
  .featureBlockEven > .content > h2 {
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 15px;
  }

  .featureBlock > .content > p,
  .featureBlockEven > .content > p {
    font-size: 25px;
    line-height: 40px;
  }

  .featureBlock > img,
  .featureBlockEven > img {
    width: 560px;
  }

  .startBlock {
    margin: 100px auto 100px 0;
  }

  .startBlock > h1 {
    margin: 0 0 0 35px;
    font-size: 25px;
    line-height: 40px;
  }
}

@media (max-width: 1190px) {
  .headSection {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    justify-content: flex-start;
    margin-top: 100px;
  }

  .headSection > .content {
    width: auto;
  }

  .headSection > img {
    width: 100%;
    max-width: 350px;
    margin-top: 40px;
  }

  .headSection > .content > h1 {
    font-size: 30px;
    width: 245px;
    line-height: 37px;
    margin: 0 0 15px;
  }

  .headSection > .content > p {
    font-size: 18px;
    line-height: 29px;
    margin: 0 0 25px;
    max-width: 350px;
  }

  .signup {
    padding: 14px 20px 16px;
    border-radius: 15px;
    font-size: 18px;
    line-height: 22px;
  }

  .stats {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 60px;
  }

  .stats > div {
    align-items: flex-start;
    margin-right: 0;
    text-align: start;
    margin-bottom: 25px;
  }

  .stats > div > h3 {
    margin: 0 0 5px;
    font-size: 30px;
    line-height: 37px;
  }

  .stats > div > p {
    font-size: 18px;
    line-height: 22px;
  }

  .featureBlock,
  .featureBlockEven {
    width: 100%;
    margin-top: 35px;
    margin-bottom: 5px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    box-sizing: border-box;
    align-self: flex-end;
    padding: 0;
  }

  .featureBlockEven {
    align-self: flex-start;
    align-items: flex-start;
  }

  .featureBlock > .content {
    align-items: flex-end;
    text-align: right;
    margin-right: 20px;
  }

  .featureBlockEven > .content {
    align-items: flex-start;
    text-align: left;
    margin-left: 20px;
  }

  .featureBlock > .content > h2,
  .featureBlockEven > .content > h2 {
    font-size: 30px;
    line-height: 37px;
    margin-bottom: 5px;
    margin-top: 20px;
  }

  .featureBlock > .content > p,
  .featureBlockEven > .content > p {
    font-size: 18px;
    line-height: 29px;
    max-width: 280px;
  }

  .featureBlock > img,
  .featureBlockEven > img {
    width: calc(100% - 20px);
    max-width: 350px;
  }

  .startBlock {
    flex-direction: column-reverse;
    align-items: flex-start;
    margin: 55px 0 60px;
  }
  
  .startBlock > h1 {
    margin: 0 0 15px 0;
    font-size: 18px;
    line-height: 29px;
  }
}
