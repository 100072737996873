.container {
  margin-top: 220px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  width: 750px;
}

.submit {
  margin-top: 35px;
  margin-bottom: 80px;
  background-color: #8aaf45;
  border-radius: 20px;
  padding: 21px 30px 26px;
  color: white;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  cursor: pointer;
  width: fit-content;
}

.disabled {
  cursor: default;
  background-color: rgb(159, 159, 159);
}

@media (max-width: 1190px) {
  .container {
    margin-top: 100px;
  }

  .content {
    width: 100%;
    max-width: 420px;
  }

  .submit {
    margin-top: 0px;
    margin-bottom: 50px;
    border-radius: 10px;
    padding: 13px 20px 15px;
    font-size: 16px;
    line-height: 20px;
  }
}
