.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-family: 'Montserrat';
  color: #424242;
  overflow-x: hidden;
}

.container {
  width: 1640px;
  box-sizing: border-box;
  padding: 0 20px;
}

@media (max-width: 1640px) {
  .container {
    width: 1150px;
    box-sizing: border-box;
    padding: 0 20px;
  }
}

@media (max-width: 1190px) {
  .container {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
  }
}

@font-face {
  font-family: 'Montserrat';
  src: url('./res/fonts/Montserrat-ExtraBold.ttf');
  font-weight: 800;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./res/fonts/Montserrat-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./res/fonts/Montserrat-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./res/fonts/Montserrat-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./res/fonts/Montserrat-Regular.ttf');
  font-weight: 400;
}
